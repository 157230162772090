<template>
  <v-row class="invite-row" no-gutters>
    <v-col cols="12" md="6" order="last" order-md="first">
      <div
        class="d-flex flex-column align-center justify-center"
        :class="checkMd ? 'image-left' : 'image-bottom'"
      >
        <div class="my-auto"></div>
        <v-icon class="mt-auto" :size="checkSm ? 325 : 180">$logoIcon</v-icon>
        <div class="d-flex my-auto pb-10">
          <v-icon class="mr-10" @click="goToLinkedin">$linkedinkIcon</v-icon>
          <v-icon @click="goToFacebook">$facebookIcon</v-icon>
        </div>
      </div>
    </v-col>
    <v-col class="d-flex justify-center align-center" cols="12" md="6" order="first" order-md="last">
      <v-card
        flat
        class="inviteCardBg invite-connect"
        :class="isXs ? 'pa-15 pa-sm-20' : 'pa-8'"
        :max-width="isMobile ? '550' : '90%'"
      >
        <div
          class="text-uppercase text-sm-end pb-12 pb-sm-20"
          :class="isMobile ? 'font-logo' : 'font-logo-xs'"
          v-if="!responseMessage && successData"
        >
          <span class="inviteTitle--text">Set your account password</span>
        </div>
    
        <h2 class="font-logo-xs inviteTitle--text py-10" v-else>{{ responseMessage }}</h2>
    
        <v-form v-model="validForm" ref="form" v-if="!responseMessage && successData">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10"
              cols="12" sm="4"
            >
              <span class="font-normal inviteColor--text">Password</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="passwordInviteUser"
              ></portal-target>
            </v-col>
            <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
              <FormField
                v-model="invitePassForm.password"
                class="login-form-input"
                :rules="rules"
                type="password"
              >
                <template #message="{ key, message }">
                  <portal to="passwordInviteUser" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
        
            <v-col
              class="d-flex flex-column text-sm-end justify-center pb-4 pb-sm-10"
              cols="12" sm="4"
            >
              <span class="font-normal inviteColor--text">Password confirm</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="userPasswordInviteUser"
              ></portal-target>
            </v-col>
            <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
              <FormField
                v-model="invitePassForm['password_confirmation']"
                class="login-form-input"
                type="password"
                :rules="passwordConfirmRules"
              >
                <template #message="{ key, message }">
                  <portal to="userPasswordInviteUser" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </FormField>
            </v-col>
        
            <v-col class="pt-4 pt-sm-12 pb-5 pb-sm-10 d-flex justify-end" cols="12">
              <ActionOutlinedButton
                class="mr-8 mr-sm-11"
                v-if="!role"
                :to="{ name: 'AuthLogin' }"
                textColor="inviteFieldBorder--text"
                text
              >
                Log in
              </ActionOutlinedButton>
          
              <ActionBtnForDarkBg
                type="submit"
                @click.prevent="register"
                :loading="loading"
              >
                Confirm
              </ActionBtnForDarkBg>
            </v-col>
          </v-row>
        </v-form>
    
        <div
          v-if="responseMessage"
          class="d-flex justify-end"
        >
          <ActionOutlinedButton
            v-if="!role"
            :to="{ name: 'AuthLogin' }"
            textColor="inviteFieldBorder--text"
            text
          >Log in</ActionOutlinedButton>
          
          <ActionOutlinedButton
            v-else
            :to="{ name: 'Reporting' }"
            textColor="inviteFieldBorder--text"
            text
          >Reporting</ActionOutlinedButton>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'

import { Types as authTypes } from '@/store/modules/auth'
import { mapState, mapActions } from 'vuex'
import { checkInviteToken, registerInvitedUser } from '@/api/invite-routes'

export default {
  name: 'InviteUsers',
  components: { FormField, ActionBtnForDarkBg, ActionOutlinedButton },
  props: {
    token: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    validForm: true,
    loading: false,
    successData: false,
    invitePassForm: {
      password: '',
      'password_confirmation': ''
    },
    responseMessage: null,
    rules: [],
    passwordConfirmRules: []
  }),
  computed: {
    ...mapState({
      role: state => state.auth.role,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width > 600
    },
    isXs() {
      return this.$vuetify.breakpoint.width > 360
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    }
  },
  methods: {
    ...mapActions({
      checkUser: authTypes.actions.GET_CHECK
    }),
    async goToCheckInviteToken(token) {
      const { success, payload, message } = await checkInviteToken(token)
      
      if (success) {
        if (payload['user_exists']) {
          if (this.role) {
            this.checkUser()
          }
          this.responseMessage = message
        } else {
          this.successData = true
        }
      } else {
        this.responseMessage = message
      }
    },
    async register() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        
        const { success, message } = await registerInvitedUser({
          token: this.token,
          formData: this.invitePassForm
        })
        
        if (success) {
          if (this.role) {
            this.checkUser()
          }
          this.responseMessage = message
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
      this.passwordConfirmRules = [
        v => !!v || 'This field is required',
        v => v === this.invitePassForm.password || 'Passwords must match'
      ]
    },
    goToFacebook() {
      window.open('https://www.facebook.com/Dialics-109364704630086', '_blank')
    },
    goToLinkedin() {
      window.open('https://www.linkedin.com/company/dialics', '_blank')
    },
  },
  watch: {
    token: {
      immediate: true,
      handler(token) {
        if (!!token) {
          this.goToCheckInviteToken(token)
        } else {
          this.$router.push({ name: 'AuthLogin' })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.invite-row {
  height: 100vh;
  
  .invite-connect {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.68);
    
    .v-input {
      &.v-text-field--outlined {
        & > .v-input__control {
          & > .v-input__slot {
            & > fieldset {
              border: 1px solid var(--v-inviteFieldBorder-base)!important;
            }
            input {
              color: var(--v-inviteFont-base)!important;
            }
          }
        }
        
        &.error--text {
          & > .v-input__control {
            & > .v-input__slot {
              & > fieldset {
                border: 1px solid var(--v-pink-base) !important;
              }
            }
          }
        }
      }
      
      &.v-text-field--solo {
        & > .v-input__control {
          & > .v-input__slot {
            background-color: var(--v-inviteInputBg-base)!important;
            
            input,
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
              border: none!important;
              color: var(--v-inviteFont-base)!important;
              -webkit-text-fill-color: var(--v-inviteFont-base)!important;
              box-shadow: 0 0 0 1000px var(--v-inviteInputBg-base) inset!important;
            }
          }
        }
      }
    }
  }
  
  .image-left {
    height: 100%;
    background-image: url(/images/invite_bg.png);
    background-position: right;
  }
  .image-bottom {
    height: 100%;
    background-image: url(/images/login_bottom_bg.png);
    background-position: top;
  }
}
</style>
